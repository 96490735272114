import {AfterViewInit, Component, ElementRef, EventEmitter, Input, Output, Renderer2} from '@angular/core';
import {TranslateModule} from "@ngx-translate/core";
import {
  NgbAccordionBody,
  NgbAccordionButton,
  NgbAccordionCollapse,
  NgbAccordionHeader, NgbAccordionItem, NgbModal
} from "@ng-bootstrap/ng-bootstrap";
import {JsonPipe, NgIf} from "@angular/common";
import {PdfViewerComponent} from "../../pdf-viewer/pdf-viewer.component";
import {DocumentRepository} from "../../../repositories/document.repository";
import {ActivatedRoute, Router} from "@angular/router";
import {NotificationService} from "../../../services/notification.service";
import {RagRepository} from "../../../repositories/rag.repository";
import {documentPartitionType, STEP_STATUS} from "../../../models/enums";
import {forkJoin, lastValueFrom} from "rxjs";
import {document} from "../../../models/documentModel";
import {MarkdownComponent} from "ngx-markdown";

@Component({
  selector: 'app-retrieval',
  standalone: true,
  imports: [
    TranslateModule,
    NgbAccordionBody,
    NgbAccordionButton,
    NgbAccordionCollapse,
    NgbAccordionHeader,
    NgbAccordionItem,
    JsonPipe,
    NgIf,
    MarkdownComponent
  ],
  templateUrl: './retrieval.component.html',
  styleUrl: './retrieval.component.css',
  styles: [`
    markdown ::ng-deep h1 {
      font-size:19px;
      font-wight:bold;
    }
    markdown ::ng-deep h2,
    markdown ::ng-deep h3,
    markdown ::ng-deep h4 {
      font-size: 16px;
      font-weight: bold;
    }
  `],
})
export class RetrievalComponent{
  @Input() step: any;
  toolingId: string | null = "";
  documents: any = [];

  @Output() loadTooling: EventEmitter<void> = new EventEmitter<void>();
  constructor(private modalService: NgbModal, private ragRepository: RagRepository,private docRepository: DocumentRepository, private route: ActivatedRoute,private renderer: Renderer2,
  private el: ElementRef) {
  }

  async ngOnInit(): Promise<void> {
    this.route.paramMap.subscribe(params => {
      this.toolingId = params.get('ragId');
    });

    this.getDocumentsForReferences();
  }

  getDocumentsForReferences() {
    const documentRequests = this.step.action.references.map((reference: { documentId: string | null; }) =>
      this.docRepository.getDocument(reference.documentId)
    );

    forkJoin(documentRequests).subscribe((documents) => {
      console.log(documents); // All documents will be returned as an array
      this.documents= documents;
    });
  }
  next() {
    this.step.stepStatus = "USER_FINALIZED";
    if (this.toolingId) {
      this.ragRepository.patchToolingStep(this.toolingId, this.step.toolingStepId, this.step).subscribe(
        async (toolingStepPatchResponse: any) => {
          console.log(toolingStepPatchResponse);
          this.loadTooling.emit();
        }
      );
    }
  }

  openFile(documentId: any, page: any) {
    const modalRef = this.modalService.open(PdfViewerComponent, {size: 'xl'});
    modalRef.componentInstance.documentId = documentId;
    modalRef.componentInstance.page = page;
  }
  getBase64Image(rawData: string): string {
    return `data:image/jpeg;base64,${rawData}`;
  }
  protected readonly STEP_STATUS = STEP_STATUS;


  protected readonly documentPartitionType = documentPartitionType;
}
