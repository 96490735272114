import {ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ChatRepository} from "../../repositories/chat.repository";
import {lastValueFrom, Subscription} from "rxjs";
import {ActivatedRoute, Router} from "@angular/router";
import {JsonPipe, NgClass, NgFor, NgIf} from "@angular/common";
import {chatMessages} from "../../models/chatMessages";
import {chat} from "../../models/chatModel";
import {MessageStatus, MessageType} from '../../models/enums';
import {AssistantMessageComponent} from "../chatTypes/assistant-message/assistant-message.component";
import {ToolSelectorComponent} from "../chatTypes/tool-selector/tool-selector.component";
import {HumanMessageComponent} from "../chatTypes/human-message/human-message.component";
import {LoaderMessageComponent} from "../chatTypes/loader-message/loader-message.component";
import {FormsModule} from "@angular/forms";
import {RedactedMessageComponent} from "../chatTypes/redacted-message/redacted-message.component";
import {NotificationService} from "../../services/notification.service";
import {LocalErrorMessageComponent} from "../chatTypes/local-error-message/local-error-message.component";
import {TranslateModule} from "@ngx-translate/core";

@Component({
  selector: 'app-chat-component',
  standalone: true,
    imports: [NgFor, NgIf, AssistantMessageComponent, ToolSelectorComponent, HumanMessageComponent, LoaderMessageComponent, RedactedMessageComponent, NgClass, FormsModule, JsonPipe, LocalErrorMessageComponent, TranslateModule],
  templateUrl: './chat-component.component.html',
  styleUrls: ['./chat-component.component.css']
})
export class ChatComponentComponent implements OnInit, OnDestroy {
  public messageResponse: any[] = []; // Assuming this is an array of messages
  public chat: chat | null = null;
  chatId: string | null = "";
  message: string = "";
  humanMessage: string = "";
  messageTypes = MessageType;
  isLoading=false;
  base64Image: string ="";
  private reloadTimeout: any;
  reloadTimeOutCount=0;
  private eventSubscription: Subscription | undefined;
  chatMessages: chatMessages | undefined = undefined;
  @ViewChild('chatContainer') private chatContainer!: ElementRef;
  @ViewChild('fileInput') fileInput: ElementRef | undefined;
  selectedFile: File | null = null;

  constructor(private chatRepository: ChatRepository, private router: Router, private route: ActivatedRoute,private notificationService: NotificationService,private cdr: ChangeDetectorRef) {
  }

  async ngOnInit(): Promise<void> {
    this.route.paramMap.subscribe(params => {
      this.chatId = params.get('id');
      if (this.chatId) {
        this.loadMessages(this.chatId);
      }
    });
  }

  async loadMessages(id: string): Promise<void> {
    this.isLoading=true;
    this.chatRepository.getMessages(id).subscribe(
      async (chatMessages: chatMessages) => {
        this.chatMessages = chatMessages;
        this.messageResponse = chatMessages.messages;
        await this.checkAndUpdateLastMessage();
        this.scrollToBottom();
        console.log('Received messages:', this.chatMessages);
    if (this.chatMessages.messages[this.chatMessages.messages.length-1].messageStatus ==MessageStatus.MESSAGE_GENERATION_PENDING && this.chatMessages.messages[this.chatMessages.messages.length-1].messageType == MessageType.PLACEHOLDER){
          this.reloadTimeout=setTimeout(async () => {
            this.reloadTimeOutCount++;
            if(this.reloadTimeOutCount>5){
              clearTimeout(this.reloadTimeout);
            }
            else{
              await this.loadMessages(id);
            }
          }, 2000);
        }
        else{
          this.isLoading=false;
        }
      },
      error => {
        console.error('Error fetching messages:', error);
        this.router.navigate(['/chat']);
      }
    );
  }

  onFileSelected(event: Event): void {

    const input = event.target as HTMLInputElement;
    if (input.files && input.files[0]) {
      this.selectedFile = input.files[0];
      const file = input.files[0];
      if (file.type === 'image/jpeg' || file.type === 'image/png') {
        const reader = new FileReader();
        reader.onload = () => {
          this.base64Image = reader.result as string;
          this.cdr.detectChanges();
        };
        reader.readAsDataURL(this.selectedFile);
        this.selectedFile = file;
      } else {
        this.notificationService.showError("Error","chat.errorFileUpload");
      }
    }
  }
  removeImage() {
    this.base64Image = "";
    this.selectedFile = null;
    if (this.fileInput) {
      this.fileInput.nativeElement.value = "";
    }
  }

  async sendMessage() {
    if (this.chatId) {
      try {
        let newMessage;
        if (this.selectedFile) {
          newMessage = await lastValueFrom(
            await this.chatRepository.newMessage(this.chatId, this.humanMessage, this.selectedFile)
          );
        } else {
          newMessage = await lastValueFrom(await this.chatRepository.newMessage(this.chatId, this.humanMessage));
        }
        await new Promise(resolve => setTimeout(resolve, 200));
        await this.loadMessages(this.chatId);
        this.humanMessage = "";
        this.selectedFile = null;
      } catch (error) {
        console.error("Üzenet küldése sikertelen:", error);
      }finally {
        this.base64Image = "";
        this.selectedFile = null;
        if (this.fileInput) {
          this.fileInput.nativeElement.value = "";
        }
      }
    } else {
      console.log("TODO: auto create");
    }
  }
  async checkAndUpdateLastMessage() {
    const lastMessage = this.messageResponse[this.messageResponse.length - 1];

    if (lastMessage.messageType === MessageType.PLACEHOLDER && lastMessage.messageStatus === 'MESSAGE_GENERATION_PENDING') {
      const messageId = lastMessage.messageId;

      this.chatRepository.getMessage(this.chatId!, messageId).subscribe(
        response => {
          // Update the last element with the response from the server
          if(response.messageId!=""){
            this.messageResponse[this.messageResponse.length - 1] = response;
            this.scrollToBottom();
          }
        },
        error => {
          console.error('Error fetching message:', error);
        }
      );
    }
  }

  ngOnDestroy(): void {
    if (this.eventSubscription) {
      this.eventSubscription.unsubscribe();
    }
    if (this.reloadTimeout) {
      clearTimeout(this.reloadTimeout);
    }
  }
  callFunction(event: { functionName: string, args: any[] }) {
    const functionName = event.functionName;
    const args = event.args;

    const functionToCall = (this as any)[functionName];
    if (functionToCall && typeof functionToCall === 'function') {
      functionToCall.apply(this, args);  // Call the function with additional parameters
    } else {
      console.warn(`Function ${functionName} not found on parent component`);
    }
  }
  scrollToBottom(): void {
    try {
      setTimeout(() => {
        this.chatContainer.nativeElement.scroll({
          top:  this.chatContainer.nativeElement.scrollHeight,
          left: 0,
          behavior: 'smooth'
        });
        console.log(this.chatContainer.nativeElement.scrollHeight);
      }, 100);
    } catch(err) {
      console.error('Error in scrollToBottom:', err);
    }
  }

  protected readonly MessageType = MessageType;
  protected readonly MessageStatus = MessageStatus;
}
