import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  BaseRepository,
  PositoryErrorHandlerService,
  Path,
  RepoPath
} from 'ngpository';
import {FormDataService} from "../services/form-data.service";
import {map} from "rxjs";
import {chatMessage} from "../models/chatMessage";
import {chatMessages} from "../models/chatMessages";
import {Templates} from "../models/TemplateModel";
import {environment} from "../../environments/environment";

@RepoPath(environment.templateServiceUrl)
@Injectable({
  providedIn: 'root'
})
export class PromtRepository extends BaseRepository<any>{
  static showLoader: boolean = true;

  constructor(httpClient: HttpClient, errorhandler: PositoryErrorHandlerService) {
    super(httpClient, errorhandler);
  }

  @Path('/')
  getTemplates() {
    return super.get().pipe(
      map(data => new Templates(data)));;
  }
  @Path('/')
  newTemplate(description: any, rawData: any,label:any) {
    let data={description: description, rawData:rawData, label:label};
    return super.post(data);
  }
  @Path('/{templateId}')
  patchTemplate(templateId: any, description: any, rawData: any,label:any) {
    let data={description: description, rawData:rawData, label:label};
    return super.patch(data);
  }
  @Path('/{templateId}')
  getTemplate(templateId: any) {
    return super.get();
  }
  @Path('/{templateId}')
  deleteItem(templateId:string) {
    return super.delete()
  }

  public setUrl(url:string) {
    const regExp = /https?:\/\/.*/gm;
    url = regExp.test(url) ? url : 'http://' + url;
    this.repoUrl = url;

  }
  public unSetUrl(){
    this.repoUrl = undefined;
  }



}
