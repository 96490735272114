import {Component, Input} from '@angular/core';
import {TranslateModule} from "@ngx-translate/core";
import {UploadDocumentComponent} from "../../upload-document/upload-document.component";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {VoteComponent} from "../../vote/vote.component";
import {VOTE_OPTION} from "../../../models/enums";
import {MarkdownComponent} from "ngx-markdown";


@Component({
  selector: 'app-assistant-message',
  standalone: true,
  imports: [
    TranslateModule,
    MarkdownComponent
  ],
  templateUrl: './assistant-message.component.html',
  styleUrl: './assistant-message.component.css'
})
export class AssistantMessageComponent {
  @Input() message: any;
  @Input() chatId: any;
  constructor(private modalService: NgbModal) {
  }

  openUploadDialog(vote:VOTE_OPTION,messageId: string): void {
    const modalRef = this.modalService.open(VoteComponent, { size: 'sm' });
    modalRef.componentInstance.vote = vote;
    modalRef.componentInstance.messageId = messageId;
    modalRef.componentInstance.chatId = this.chatId;
    modalRef.result.then((result) => {
      if (result) {
        console.log('Vote uploaded successfully');
      }
    }).catch((error) => {
      console.error('Modal dismissed', error);
    });
  }

  protected readonly VOTE_OPTION = VOTE_OPTION;
}
