import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {
  BaseRepository,
  PositoryErrorHandlerService,
  Path,
  RepoPath
} from 'ngpository';
import {Plugin} from "../models/PluginModel";
import {environment} from "../../environments/environment";


@RepoPath(environment.pluginServiceUrl)
@Injectable({
  providedIn: 'root'
})
export class PluginRepository extends BaseRepository<any> {
  static showLoader: boolean = true;

  constructor(httpClient: HttpClient, errorhandler: PositoryErrorHandlerService) {
    super(httpClient, errorhandler);
  }

  @Path('/')
  getPlugins() {
    /* return super.get().pipe(
       map(data => new Templates(data)));;*/
    return super.get();
  }

  @Path('/{pluginId}')
  getPlugin(pluginId: string) {
    return super.get();
  }

  @Path('/{pluginId}/status')
  publishPlugin(pluginId: string) {
    let data = {};
    return super.post(data);
  }

  @Path('/{pluginId}/status')
  unpublishPlugin(pluginId: string) {
    return super.delete();
  }

  @Path('/{pluginId}')
  patchPlugin(pluginId: string, displayName: any, modelName: any, modelDescription: any, humanDescription: any, certificatePrivateKey: any, certificatePublicKey: any, authMethod: any, url: any, openapi: any) {
    let data={url: url, certificatePrivateKey:certificatePrivateKey,certificatePublicKey:certificatePublicKey, openapi:openapi, authMethod:authMethod,displayName: displayName, modelName: modelName, modelDescription: modelDescription, humanDescription:humanDescription};
    return super.patch(data);
  }

  @Path('/')
  newPlugin(displayName: any, modelName: any, modelDescription: any, humanDescription: any, certificatePrivateKey: any, certificatePublicKey: any, authMethod: any, url: any, openapi: any) {
    let data={url: url, certificatePrivateKey:certificatePrivateKey,certificatePublicKey:certificatePublicKey, openapi:openapi, authMethod:authMethod,displayName: displayName, modelName: modelName, modelDescription: modelDescription, humanDescription:humanDescription};
    return super.post(data);
  }

  @Path('/{pluginId}')
  deleteItem(pluginId: string) {
    return super.delete()
  }

  @Path('/')
  save(plugin: any) {
    return super.post(plugin);
  }

  public setUrl(url: string) {
    const regExp = /https?:\/\/.*/gm;
    url = regExp.test(url) ? url : 'http://' + url;
    this.repoUrl = url;

  }

  public unSetUrl() {
    this.repoUrl = undefined;
  }


}
