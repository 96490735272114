<div class="sidebar" [ngClass]="{ 'expanded': isExpanded }">
  <div class="header">
    <h1 class="title">
      Chats
    </h1>
    <i class="bi bi-plus-circle addChatIcon d-none"  [ngClass]="{ 'visible': isExpanded }"  (click)="newChats()" style="cursor: pointer" data-bs-toggle="tooltip" data-bs-placement="top" title="{{'chat.addNew' | translate}}"></i>
    <div class="toggle" (click)="handleSidebarToggle()">
      <span class="chevron"></span>
    </div>
  </div>
  <div class="chatContainer content">
    <div class="link">
      <ul>
      <div *ngIf="chats?.chats?.length; else noChats">
          <li *ngFor="let chatItem of chats?.chats">
            <div class="content" [routerLink]="['/chat/'+chatItem.chatId]" [routerLinkActive]="'active'">
              <div class="row  w-100">
                <div class="col-11 m-0 p-0">
                  <div *ngIf="chatItem.chatTitle" class="title">{{chatItem.chatTitle}} </div>
                  <div *ngIf="!chatItem.chatTitle" class="title">{{chatItem.chatId}} </div>
                </div>
                <div class="col-1 m-0 p-0">
                  <fa-icon [icon]="faTrash" class="m-0 trashIcon" [ngClass]="{ 'expanded': isExpanded }" (click)="showSwal(chatItem.chatId)"></fa-icon>
                </div>
              </div>
            </div>
          </li>

      </div>
      </ul>
      <ng-template #noChats>
        <p>No chats available</p>
      </ng-template>
    </div>
  </div>
</div>
