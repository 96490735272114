import {Component, EventEmitter, Input, Output} from '@angular/core';
import {faTrash} from '@fortawesome/free-solid-svg-icons';
import {CommonModule} from "@angular/common";
import {ActivatedRoute, Router, RouterModule} from "@angular/router";
import {FontAwesomeModule} from "@fortawesome/angular-fontawesome";
import {NotificationService} from "../../services/notification.service";
import {lastValueFrom} from "rxjs";
import {ChatRepository} from "../../repositories/chat.repository";
import {chat, chats} from "../../models/chatModel";
import Swal from 'sweetalert2'
import {EventStreamService} from "../../services/event-stream.service";
import {TranslateModule} from "@ngx-translate/core";

@Component({
  selector: 'app-chat-side-bar',
  templateUrl: './chat-side-bar.component.html',
  styleUrl: './chat-side-bar.component.css',
  standalone: true,
  imports: [CommonModule, RouterModule, FontAwesomeModule, TranslateModule]
})
export class ChatSideBarComponent {
  public chats: chats | null = null;
  public chat: chat | null = null;
  chatId: string | null = "";
  @Input() isExpanded: boolean = false;
  @Output() toggleSidebar: EventEmitter<boolean> = new EventEmitter<boolean>();
  faTrash = faTrash;


  constructor(private notificationService: NotificationService, private chatRepository: ChatRepository,private route: ActivatedRoute,private router: Router,) {
  }

  async ngOnInit(): Promise<void> {
    await this.getChats();
    this.route.paramMap.subscribe(params => {
      this.chatId = params.get('id');
    });
  }

  handleSidebarToggle = () => this.toggleSidebar.emit(!this.isExpanded);


  createNotification() {
    this.notificationService.showError("kecske", "general.info");
  }

  async getChats() {
    this.chats = await lastValueFrom(this.chatRepository.getChats());
    console.log(this.chats);
  }

  async newChats() {
    this.chat = await lastValueFrom(this.chatRepository.newChat());
    this.chats = await lastValueFrom(this.chatRepository.getChats());
    this.router.navigate(['/chat/'+ this.chat?.chatId]);
  }

  async deleteChat(chatId: string) {
    await lastValueFrom(this.chatRepository.deleteChat(chatId));

    if(this.chatId==chatId){
      this.router.navigate(['/chat']);
    }
    else{
      await new Promise(resolve => setTimeout(resolve, 1000));
      this.chats = await lastValueFrom(this.chatRepository.getChats());
      this.router.navigate(['/chat/'+this.chatId]);
    }
  }

  showSwal(chatId: string) {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-success",
        cancelButton: "btn btn-danger"
      },
      buttonsStyling: false
    });
    swalWithBootstrapButtons.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, cancel!",
      reverseButtons: true
    }).then(async (result) => {
      if (result.isConfirmed) {
        await this.deleteChat(chatId)
        swalWithBootstrapButtons.fire({
          title: "Deleted!",
          text: "Your chat has been deleted.",
          icon: "success"
        });
      } else if (
        /* Read more about handling dismissals below */
        result.dismiss === Swal.DismissReason.cancel
      ) {
        swalWithBootstrapButtons.fire({
          title: "Cancelled",
          text: "Your chat flow is safe :)",
          icon: "error"
        });
      }
    });
  }
}
